*,
*:before,
*:after {
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.333rem;
    font-family: Poppins, --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    word-wrap: break-word;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

* {
    padding: 0;
    margin: 0;
}

body {
    background-image: linear-gradient(90deg, #3380cc 0%, #5299e0 100%);
}

#root {
    padding-top: 6rem;
    background-color: hsla(210, 0%, 98%, 1);
}

.avatar,
.avatar:visited {
    height: 8rem;
    width: 8rem;
    border-radius: 6rem;
    box-shadow: 0px 3px 6px hsla(210, 0%, 0%, 0.2);
    background-color: hsla(210, 0%, 100%, 1);
    border: 4px solid hsla(210, 0%, 100%, 1);
    transition: transform 250ms ease-out;
}

.student {
    margin-bottom: 0.5rem;
}

figure {
    box-shadow: 0px 9px 27px hsla(210, 0%, 0%, 0.15);
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    background-color: hsla(210, 0%, 100%, 1);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

figure p {
    flex-basis: 100%;
    font-size: 0.875rem;
    text-align: center;
}

th,
td {
    padding: 0.5rem 1rem;
}

td a {
    background-color: hsla(210, 60%, 50%, 0.25);
    text-decoration: none;
    padding: 2px 0.5rem 2px;
    border-radius: 3px;
    color: hsla(210, 60%, 50%, 1);
}

td a:hover {
    background-color: hsla(210, 60%, 50%, 0.4);
    color: hsla(210, 60%, 50%, 1);
}

tr:nth-child(odd):not(.skip) {
    background-color: hsla(210, 60%, 50%, 0.1);
}

th {
    text-align: left;
}

th img {
    height: 8px;
    padding-left: 0.5rem;
}

th:first-of-type {
    text-align: right;
    border-top-left-radius: 0.5rem;
}

th:last-of-type {
    border-top-right-radius: 0.5rem;
}

tr:last-of-type td {
    border-radius: none;
}

.center {
    text-align: center;
}

td:first-of-type {
    text-align: right;
}

table {
    height: 0px;
    border-spacing: 0;
    box-shadow: 0px 9px 27px hsla(210, 0%, 0%, 0.15);
    border-radius: 0.5rem 0.5rem 0 0;
}

nav {
    height: 6rem;
    background-image: linear-gradient(90deg, #3380cc 0%, #5299e0 100%);
    padding: 0 5rem;
    box-shadow: 0px 9px 27px hsla(210, 0%, 0%, 0.15);
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 0;
}

nav ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
}

nav ul li {
    padding-right: 1.5rem;
    line-height: 5.75rem;
}

nav ul li a {
    text-decoration: none;
    color: hsla(210, 0%, 100%, 0.7);
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 1rem;
    transition: color ease-in 100ms;
}

nav ul li a:hover {
    color: hsla(210, 0%, 100%, 1);
}

nav ul li a.active {
    color: hsla(210, 0%, 100%, 1);
}

nav img {
    height: 60px;
    position: relative;
    top: 18px;
}

nav img:hover {
    cursor: pointer;
    animation: rotate-and-scale 1s ease-in-out both;
}

@keyframes rotate-and-scale {
    0% {
        -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
        transform: scale(1) rotate3d(1, 1, 0, 0deg);
    }
    50% {
        -webkit-transform: scale(0.5) rotate3d(1, 1, 0, -180deg);
        transform: scale(0.5) rotate3d(1, 1, 0, -180deg);
    }
    100% {
        -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
        transform: scale(1) rotate3d(1, 1, 0, -360deg);
    }
}

main {
    margin: 3rem 5rem 3rem 5rem;
    color: hsla(210, 0%, 100%, 0.8);
    font-family: Poppins-Bold;
    font-size: 40px;
    color: hsl(210, 0%, 40%);
    display: grid;
    justify-content: center;
    justify-items: center;
    min-width: 800px;
}

main ul {
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    box-shadow: 0px 9px 27px hsla(210, 0%, 0%, 0.15);
    background-color: hsl(0, 0%, 100%);
    border-radius: 0.5rem;
    padding: 4rem 2rem 2rem;
}

main ul li {
    width: 20%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 4rem;
}

main ul li a,
.toTableview {
    width: 100%;
    text-decoration: none;
    color: hsl(210, 60%, 50%);
    font-weight: 700;
    text-align: center;
}

main ul li a:hover,
.toTableview:hover {
    text-decoration: underline;
}

main ul li a:last-of-type:hover {
    background-color: hsla(45, 100%, 60%, 1);
    text-decoration: none;
}

main ul li a img {
    margin: 0.5rem 0;
}

main ul li a:hover img {
    transform: scale(1.1);
}

main ul li a:last-of-type,
.toTableview,
.toTableview:visited {
    background-color: hsla(45, 90%, 50%, 1);
    border-radius: 3px;
    width: auto;
    padding: 0.25rem 0.5rem 0.25rem;
    color: hsla(0, 0%, 100%, 1);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.toTableview {
    width: inherit;
    justify-self: center;
    margin-top: 1rem;
}

.toTableview:hover {
    background-color: hsla(45, 100%, 60%, 1);
    text-decoration: none;
}

main ul li a:last-of-type:active,
.toTableview:active {
    position: relative;
    top: 2px;
}

header {
    display: grid;
    justify-items: center;
    margin-bottom: 2rem;
}

header h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    color: hsl(0, 0%, 30%);
}

h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: hsl(0, 0%, 30%);
    flex-basis: 100%;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

footer {
    padding: 1rem 5rem;
    background-image: linear-gradient(90deg, #3380cc 0%, #5299e0 100%);
    color: hsla(210, 0%, 100%, 0.7);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

footer span a {
    color: hsla(210, 0%, 100%, 0.7);
    text-decoration: none;
    font-weight: 700;
}

footer span a:hover {
    color: hsla(210, 0%, 100%, 1);
}

.tableview {
    min-width: 800px;
}

.tableview th,
.tableview td {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    font-weight: 400;
}

.tableview th {
    font-weight: 700;
}

.tableview th {
    text-align: left;
    background-image: linear-gradient(0deg, #3380cc 0%, #5299e0 100%);
    color: hsla(210, 0%, 100%, 0.7);
    border-color: hsl(210, 60%, 50%);
    cursor: pointer;
}

.tableview th:hover {
    color: hsla(210, 0%, 100%, 1);
}

.tableview th:first-of-type {
    text-align: left;
}

.tableview td:first-of-type {
    text-align: left;
}

.tableview td:nth-of-type(3),
.tableview td:nth-of-type(4) {
    text-align: center;
}

.tableview table {
    height: 0px;
    border-spacing: 0;
    border-collapse: collapse;
    margin-top: 3rem;
    min-width: 800px;
}

.studentTable {
    width: 40%;
}

.difficultyRating,
.enjoymentRating,
.difficultyRating:visited,
.enjoymentRating:visited,
.chartType,
.chartType:visited,
.checkBox,
.checkBox:visited {
    appearance: none;
    border: none;
    background-color: hsla(45, 90%, 50%, 1);
    border-radius: 3px;
    width: auto;
    padding: 0.25rem 0.5rem 0.25rem;
    color: hsla(0, 0%, 100%, 1);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;
    margin: 0.5rem 0 0 0.5rem;
    font-weight: 700;
    cursor: pointer;
}

.enjoymentRating,
.checkBox {
    background-color: hsla(30, 90%, 50%, 1);
}

.chartType {
    background-color: hsla(210, 60%, 60%, 1);
}

.difficultyRating:hover,
.enjoymentRating:hover,
.chartType:hover {
    text-decoration: none;
    background-color: hsla(45, 100%, 60%, 1);
}

.enjoymentRating:hover,
.checkBox:hover {
    background-color: hsla(30, 100%, 60%, 1);
}

.chartType:hover {
    background-color: hsla(210, 65%, 65%, 1);
}

.difficultyRating:focus,
.enjoymentRating:focus,
.chartType:focus,
.checkBox:focus {
    outline: none;
}

.difficultyRating:active,
.enjoymentRating:active,
.chartType:active,
.checkBox:active {
    position: relative;
    top: 2px;
}

.difficultyRating span,
.enjoymentRating span,
.chartType span,
.checkBox span {
    font-size: inherit;
    padding-left: 4px;
    border-left: 2px solid hsla(0, 0%, 100%, 0.5);
}

.checkBox {
    margin-left: 0;
}

form {
    display: flex;
}

.filterByStudent select {
    appearance: none;
    border: none;
    background: url('/arrow.svg') no-repeat right center, hsla(45, 90%, 50%, 1);
    background-size: 16px 12px, auto;
    border-radius: 3px;
    width: auto;
    padding: 0.25rem 1.5rem 0.25rem 1rem;
    color: hsla(0, 0%, 100%, 1);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;
    font-weight: 700;
    position: relative;
    cursor: pointer;
}

.filterByStudent select:focus {
    outline: none;
}

.filterByStudent select:hover {
    background-color: hsla(45, 100%, 60%, 1);
}

.filterByStudent {
    background-color: hsla(45, 90%, 50%, 1);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0 0 0 0.5rem;
    border-radius: 3px;
    color: hsla(0, 0%, 100%, 1);
}

.filterByStudent span {
    padding-right: 0.25rem;
    border-right: 2px solid hsla(0, 0%, 100%, 0.5);
    font-size: inherit;
}

.dimmed {
    opacity: 0.5;
}

.gray {
    background-color: hsl(0, 0%, 75%);
}
